import React, { useState, useEffect } from "react"
import { getAllShops } from "../api/public/shops"
import HomePageState from "./Homemade/HomePage/state/HomePageState"
import SearchPageState from "./Homemade/SearchPage/state/SearchPageState"
import NotificationPageState from "./Homemade/NotificationPage/state/NotificationPageState"
import { reverseGeocode } from "../api/public/google_api"
import { navigate } from "gatsby"
import CheckoutPageState from "./Homemade/CheckoutPage/state/CheckoutPageState"
import VortexContext from "./Vortex/context/VortexContext"
import socket from "../services/socketio"
import SecureLS from "secure-ls"
import { getStoreEnvById } from "../api/public/store-env"

//Global state for user's currently selected address
export const CurrentSelectedAddress = React.createContext({})

//Global state for user's home made cart
export const HomeMadeCartContext = React.createContext([])

//Global state of homemade bottom navigation bar main index page
export const HomemadeBottomNavigationIndex = React.createContext(0)

//Global state of login
export const LoginState = React.createContext(false)

//Global state of bubble visibility
export const ShowBubbleContext = React.createContext(false)

//Global state of cashier status
export const UserStatus = React.createContext(1)

//Global state of store status
export const StoreStatus = React.createContext(1)

//Global state of dirham equivalent
export const PlatformVariables = React.createContext({})

const GlobalStates = ({ children }) => {
  const ls = new SecureLS({ encodingType: "aes" })

  let itemsBag = JSON.parse(localStorage.getItem("bagItems"))?.length > 0 ? JSON.parse(localStorage.getItem('bagItems')) : []
  //This is the code for retrieving the bagitems in local storage
  const [bagItems, updateBag] = useState(itemsBag)

  //This is the code for saving the bagitems in local storage
  useEffect(() => {
    if (bagItems?.length > 0) {
      localStorage.setItem("bagItems", JSON.stringify(bagItems))
    } else {
      console.log('setting ')
      localStorage.setItem("bagItems", JSON.stringify([]))
    }
  }, [bagItems])

  const [currentSelectedAddress, setcurrentSelectedAddress] = useState({
    address: "934-974, P. Paredes St, Sampaloc, Manila, 1008 Metro Manila",
    lng: 120.9868052,
    lat: 14.6038269,
    refreshLists: false,
  })

  const [userStatus, setUserStatus] = useState(1)

  const [storeStatus, setstoreStatus] = useState(1)

  const [isLoggin, setisLoggin] = useState(false)

  const [showBubbleState, setShowBubbleState] = useState(false)

  const [platformVariables, setPlatformVariables] = useState({})

  const [homeMadeBottomNavigationIndex, sethomeMadeBottomNavigationIndex] =
    useState(0)

  // function getCurrentLocation() {
  //   //todo Check if the persmission to use the location is allowed
  //   //todo If user didn't accept autolocation

  //   //Get current location
  //   navigator.geolocation.getCurrentPosition(
  //     function (position) {
  //       //

  //       //start reverse geocoding
  //       reverseGeocode(
  //         position.coords.longitude,
  //         position.coords.latitude
  //       ).then((response) => {
  //         //

  //         //Set useState value
  //         setcurrentSelectedAddress((prevState) => ({
  //           ...prevState,
  //           address: response?.results[0]?.formatted_address,
  //           lng: position.coords.longitude,
  //           lat: position.coords.latitude,
  //           refreshLists: true,
  //         }))
  //       })
  //     },
  //     function (error) {
  //       //

  //       if (error.code === 1) {
  //         navigate("/MapPage")
  //       }
  //     }
  //   )
  // }

  function loadPlatformVariables() {

    let store = ls.get("store")

    console.log(store)




    getStoreEnvById({ storeEnvId: store?.storeEnv?._id }).then((result) => {

      setPlatformVariables(result)
    }).catch(error => {
      alert(error)
    })
  }

  useEffect(() => {
    // getCurrentLocation()
    if (isLoggin) {
      let userId = ls.get("userId")
      let store = ls.get("store")



      /**
       * Get the current store environment config / data
       */
      loadPlatformVariables()

      /**
      * This code will be executed if admin updates user data
      */
      socket.on(`receive-new-userdata-${userId}`, (data) => {
        setUserStatus(data?.status)
      })

      /**
       * This code will be executed if admin updates store data
       */
      socket.on(`receive-new-storedata-${store?._id}`, (data) => {


        //Update local store data
        ls.set("store", data)

        //Update global store state
        setstoreStatus(data?.status)


        //Update global store env state
        setPlatformVariables(data?.storeEnv)
      })


      /**
      * This code will be executed if admin updates store environment data
      */
      socket.on(`store-env-update-${store?.storeEnv?._id}`, (data) => {

        console.log(data)

        //Update global store env state
        setPlatformVariables(data)
      })
    }
  }, [isLoggin])

  return (
    <ShowBubbleContext.Provider value={[showBubbleState, setShowBubbleState]}>
      <CurrentSelectedAddress.Provider
        value={[currentSelectedAddress, setcurrentSelectedAddress]}
      >
        <LoginState.Provider value={[isLoggin, setisLoggin]}>
          <PlatformVariables.Provider value={[platformVariables, setPlatformVariables]}>
            <StoreStatus.Provider value={[storeStatus, setstoreStatus]}>
              <UserStatus.Provider value={[userStatus, setUserStatus]}>
                <HomemadeBottomNavigationIndex.Provider
                  value={[
                    homeMadeBottomNavigationIndex,
                    sethomeMadeBottomNavigationIndex,
                  ]}
                >
                  <HomeMadeCartContext.Provider value={[bagItems, updateBag]}>
                    <VortexContext>
                      <HomePageState>
                        <SearchPageState>
                          <NotificationPageState>{children}</NotificationPageState>
                        </SearchPageState>
                      </HomePageState>
                    </VortexContext>
                  </HomeMadeCartContext.Provider>
                </HomemadeBottomNavigationIndex.Provider>
              </UserStatus.Provider>
            </StoreStatus.Provider>
          </PlatformVariables.Provider>
        </LoginState.Provider>
      </CurrentSelectedAddress.Provider>
    </ShowBubbleContext.Provider>
  )
}

export default GlobalStates
