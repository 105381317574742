
import { API } from "../../api-config"


export const signIn = async (email, password) => {

  return await fetch(`${API}/auth/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let jsonData = await response.json()
        throw Error(JSON.stringify(jsonData))
      }
      return response.json()
    })
    .catch((err) => {

      throw err
    })
}


export const signUp = async (userData) => {
  return await fetch(`${API}/auth/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const resetPassword = async (email) => {
  return await fetch(`${API}/auth/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const changePassword = async ({ currentPassword, newPassword, token }) => {

  let reqBody = {
    "currentPassword": currentPassword,
    "newPassword": newPassword
  }

  return await fetch(`${API}/auth/change-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(reqBody),
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let jsonData = await response.json()
        throw Error(JSON.stringify(jsonData))
      }
      return response.json()
    })
    .catch((err) => {

      throw err
    })
}


