import React, { useState } from "react";

import { Alert, AlertTitle, Button, Card, CardContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import VisibilityIcon from "@material-ui/icons/Visibility"

import CollapseAlert from "./Alerts/CollapseAlert";

import { Form, Formik } from "formik";

import * as yup from "yup"

import { changePassword } from "../api/public/auth";






const validationSchema = yup.object({
  newPassword: yup.string(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
})




const ChangePassword = ({ onSuccess = () => { }, token }) => {

  const [viewCurrentPassword, setViewCurrentPassword] = useState(false)

  const [viewNewPassword, setNewPassword] = useState(false)

  const [viewConfirmPassword, setConfirmPassword] = useState(false)

  const [alertState, setAlertState] = useState({
    open: false,
    severity: "success",
    message: "message"
  })

  return (
    <div style={{
      position: "relative",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Card>
        <CardContent>
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={async (data) => {
              try {

                let result = await changePassword({
                  currentPassword: data?.currentPassword,
                  newPassword: data?.newPassword,
                  token: token
                })

                onSuccess(result)

              } catch (error) {

                setAlertState((prevState) => ({
                  ...prevState,
                  open: true,
                  severity: "error",
                  message: JSON.parse(error.message).error
                }))

              }
            }}
          >
            {
              ({ isSubmitting, handleChange, errors }) => {
                return (
                  <Form>
                    <Stack spacing={2} minWidth={"300px"}>
                      <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Please update your password
                      </Alert>
                      <TextField
                        required
                        name={"currentPassword"}
                        type={viewCurrentPassword ? "text" : "password"}
                        onChange={handleChange}
                        label={"Current password"}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => {
                                setViewCurrentPassword(!viewCurrentPassword)
                              }}
                            >
                              {viewCurrentPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                      <TextField
                        required
                        name={"newPassword"}
                        type={viewNewPassword ? "text" : "password"}
                        onChange={handleChange}
                        label={"New password"}
                        error={errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => {
                                setNewPassword(!viewNewPassword)
                              }}
                            >
                              {viewNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                      <TextField
                        required
                        name={"confirmPassword"}
                        type={viewConfirmPassword ? "text" : "password"}
                        onChange={handleChange}
                        label={"Confirm password"}
                        error={errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => {
                                setConfirmPassword(!viewConfirmPassword)
                              }}
                            >
                              {viewConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                      <CollapseAlert {...alertState} />
                      <Button disabled={isSubmitting} type={"submit"} variant="contained">{isSubmitting ? "Please wait..." : "UPDATE PASSWORD"}</Button>
                    </Stack>
                  </Form>
                )
              }
            }
          </Formik>
        </CardContent>
      </Card>
    </div>

  )
}

export default ChangePassword